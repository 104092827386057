import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PUSH_WORKBENCH } from '@/core/flux.service';
import { AgentType, ChatMessage, FontSize } from '@/aiAssistant/aiAssistant.types';

export function setAIAssistantDisplayed(displayed: boolean, ignore = false) {
  flux.dispatch('AI_ASSISTANT_DISPLAYED', { displayed }, ignore ? PUSH_IGNORE : PUSH_WORKBENCH);
}

export function setAIAssistantMaximized(maximized: boolean) {
  flux.dispatch('AI_ASSISTANT_MAXIMIZED', { maximized }, PUSH_WORKBENCH);
}

export function setAIAssistantMessages(messages: ChatMessage[]) {
  flux.dispatch('AI_ASSISTANT_SET_MESSAGES', { messages }, PUSH_WORKBENCH);
}

export function setSelectedAgent(agent: AgentType) {
  flux.dispatch('AI_ASSISTANT_SET_SELECTED_AGENT', { agent }, PUSH_WORKBENCH);
}

export function setAIAssistantFontSize(fontSize: FontSize) {
  flux.dispatch('AI_ASSISTANT_SET_FONT_SIZE', { fontSize }, PUSH_WORKBENCH);
}
export function setAIAssistantExternalPrompt(externalPrompt: string | undefined) {
  flux.dispatch('AI_ASSISTANT_EXTERNAL_PROMPT', { externalPrompt });
}
