/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410161709-CDH
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAccessKeysApiClass {
 
   constructor() {}

  /**
   * @summary Creates a new Access Key associated with the current session.
   */
  public createKey(
    body: models.AccessKeyInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/accesskeys`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AccessKeyOutputV1>;
  }

  /**
   * @summary Delete an Access Key
   * @param {string} keyName - Key Information
   */
  public deleteKey(
    {
      keyName
    } : {
      keyName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(keyName)) {
      throw new Error("'keyName' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/accesskeys/${encodeURIComponent(String(keyName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Gets the Access Keys associated with the current session's user.
   * @param {number} [offset=0] - The pagination offset, the index of the first collection key that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection keys that will be returned in this page of results
   * @param {boolean} [getAll=false] - Get all user keys if true, only the current user&#x27;s keys if false.
   */
  public getKeys(
    {
      offset,
      limit,
      getAll
    } : {
      offset?: number,
      limit?: number,
      getAll?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/accesskeys`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['getAll']: getAll
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AccessKeyOutputListV1>;
  }

}


export const sqAccessKeysApi = new sqAccessKeysApiClass();
